.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.login-card {
    width: 400px;
}

.login-form-button-container {
    display: flex;
    justify-content: center;
}

.login-form-button {
    width: 100%;
}

.login-title {
    text-align: center;
    margin: 0 auto;
}